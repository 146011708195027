import clsx from 'clsx';
import { FC } from 'react';

import { DisplayItem } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';

import { Text } from '../../atoms';
import { sprinkles } from '../../sprinkles.css';
import * as styles from './cart-item.css';
import placeholderIcon from './placeholer.svg';
import { Price } from './price';

type Props = Omit<DisplayItem, 'image'> & {
  appType?: 'widget' | 'page';
  details?: string;
  currency: string | undefined;
  disableQuantity?: boolean;

  image?: {
    src?: string;
    fit: 'cover' | 'contain';
    alt: string;
  };
};
type CartItemImageProps = {
  image: NonNullable<Props['image']>;
};

export const CartItemImage = ({ image }: CartItemImageProps) => {
  const isPlaceholder = !image.src;

  const imageSrc = image.src || placeholderIcon;

  return (
    <div
      data-branding="cart-item-image-container"
      className={clsx(styles.imageWrapper, {
        [sprinkles({ padding: 12 })]: isPlaceholder,
      })}
    >
      <img
        alt={image.alt}
        src={imageSrc}
        className={clsx(styles.image, { [styles.imageFitVariant[image.fit]]: !isPlaceholder })}
      />
    </div>
  );
};

export const CartItem: FC<Props> = ({
  name,
  quantity,
  disableQuantity,
  sku,
  appType,
  image,
  details,
  discount,
  price,
  currency,
}) => {
  const formatMessage = useFormatMessage();
  return (
    <div className={styles.item} key={sku} data-test={`${appType}-cart-item-${name}`}>
      {image && <CartItemImage image={image} />}
      <div className={styles.content}>
        <div className={styles.detailsContainer}>
          <Text textStyle="light1420" textColor="black" textAlign="start">
            {quantity && !disableQuantity ? `${quantity}${quantityMultiplicationSign} ` : ''}
            {name || formatMessage('CART_ITEMS.UNNAMED_ITEM')}
          </Text>
          {details ? (
            <Text textStyle="light1420" textColor="darkGrey">
              {details}
            </Text>
          ) : null}
        </div>

        {price !== undefined && <Price price={price} discount={discount} currency={currency} />}
      </div>
    </div>
  );
};

export const quantityMultiplicationSign = '\u00D7';
