import { DehydratedState, Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { AppContext, AppProps } from 'next/app';
import DefaultApp from 'next/app';
import Head from 'next/head';
import Image, { ImageProps } from 'next/image';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import { Fragment, useEffect, useState } from 'react';

import { AnalyticsProvider } from '@tracking/data';
import { I18nProvider } from '@tracking/i18n';
import { ImageComponentProvider } from '@tracking/ui';
import '@tracking/ui/styles/normalize.css';

import { getLocaleMessages } from '$src/modules/data/api/locale-messages';
import { LangStore } from '$src/modules/i18n';
import '$src/styles';

const App = ({
  Component,
  pageProps,
  messages,
}: AppProps & {
  messages: Record<string, string>;
  dehydratedState: DehydratedState;
}) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: { refetchOnWindowFocus: false, refetchOnReconnect: false },
        },
      })
  );
  const { pathname, locale = 'en-US' } = useRouter();

  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_API_HOST,
      autocapture: false,
      capture_pageview: false,
      persistence: 'memory', // avoid data storage due to GDPR regulations
      bootstrap: {
        // overrides the distinctId in all events to make it unrelatable to the user, which would otherwise require collecting GDPR consent
        distinctID: 'anonymized',
      },

      loaded: posthog => {
        if (process.env.NEXT_PUBLIC_ENV !== 'production') {
          posthog.opt_out_capturing();
        }
        if (pathname === '/') {
          posthog.capture('portal_view', { source: 'portal' });
        }
      },
    });
  }, []);

  return (
    <Fragment>
      <Head>
        <title>Track your delivery | Ingrid Tracking Portal</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
        <link
          rel="preload"
          href="https://cdn.ingrid.com/static/assets/UniversalSans.630.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="https://cdn.ingrid.com/static/assets/UniversalSans.450.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
      </Head>

      <AnalyticsProvider
        methods={{
          capture: (event, properties) => {
            posthog.capture(event, {
              ...properties,
              source: pathname === '/' ? 'portal' : 'page',
            });
          },
          identify: _identity => {
            // Don't identify user due to GDPR concerns
            // posthog.identify(identity.analyticsId, {
            //   tos_id: identity.tosId,
            //   source: pathname === '/' ? 'portal' : 'page',
            // });
          },
          registerProperties: properties => {
            posthog.register(properties);
          },
          unregisterProperties: (...keys: string[]) => {
            keys.forEach(key => posthog.unregister(key));
          },
        }}
      >
        <I18nProvider locale={locale} localeMessages={messages}>
          <ImageComponentProvider
            value={({ src = '', alt = '', width, height, placeholder: _, ...props }) => (
              <Image
                src={src}
                alt={alt}
                width={width as ImageProps['width']}
                height={height as ImageProps['height']}
                {...props}
              />
            )}
          >
            <LangStore>
              <QueryClientProvider client={queryClient}>
                <Hydrate state={pageProps.dehydratedState}>
                  <Component {...pageProps} />
                </Hydrate>
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
              </QueryClientProvider>
            </LangStore>
          </ImageComponentProvider>
        </I18nProvider>
      </AnalyticsProvider>
    </Fragment>
  );
};

App.getInitialProps = async (props: AppContext) => {
  const initialProps = await DefaultApp.getInitialProps(props);
  const messages = await getLocaleMessages(props.ctx.locale);

  return { ...initialProps, messages: messages };
};

export default App;
