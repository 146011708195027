import { useFormatMessage } from '@tracking/i18n';

import { Button, Image, Stack, Text } from '../../atoms';
import { Modal } from '../../molecules';
import bankIdLogo from './bank-id-logo.svg';
import * as styles from './identify.css';
import { IdentifyMethod } from './types';

type MethodSelectionProps = {
  onClose: () => void;
  onMethodSelected: (method: IdentifyMethod) => void;
  appStarting: boolean;
};
export const MethodSelection = ({
  onMethodSelected,
  onClose,
  appStarting,
}: MethodSelectionProps) => {
  const formatMessage = useFormatMessage();
  return (
    <Modal
      headerText={formatMessage('IDENTIFY.METHOD_SELECTION.MODAL_TITLE')}
      onClose={onClose}
      headerSpacing="dense"
      bodySpacing="none"
      footerDivider
      fullscreen
      shrinkBody
      footerContent={
        <Stack space={16}>
          <Button
            buttonType="primary"
            onClick={() => onMethodSelected('current-device-auth')}
            isLoading={appStarting}
          >
            <Text textColor="white">{formatMessage('IDENTIFY.METHOD_SELECTION.BANK_ID_APP')}</Text>
          </Button>
          <Button buttonType="secondary" onClick={() => onMethodSelected('external-device-auth')}>
            <Text>{formatMessage('IDENTIFY.METHOD_SELECTION.DIFFERENT_DEVICE_BANK_ID')}</Text>
          </Button>
        </Stack>
      }
    >
      <Stack padding={16} space={16} data-branding="method-selection">
        <div className={styles.bankIdBrandSection}>
          <Image
            width={85}
            height={80}
            src={bankIdLogo}
            alt={formatMessage('IDENTIFY.BANK_ID_LOGO')}
          />
        </div>
        <Stack space={2} data-branding="instructions">
          <Text textStyle="regular1420" textColor="darkGrey" data-branding="block-label">
            {formatMessage('IDENTIFY.METHOD_SELECTION.INSTRUCTIONS.HEADER')}
          </Text>
          <Text textStyle="regular1420" data-branding="block-text">
            {formatMessage('IDENTIFY.METHOD_SELECTION.INSTRUCTIONS.CONTENT')}
          </Text>
        </Stack>
      </Stack>
    </Modal>
  );
};
