import { FC } from 'react';

import {
  DeliveryType,
  FrontendAlert,
  FrontendAlertType,
  PickupLocationType,
  ProgressItem,
  getCurrentStatus,
} from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';

import { ProgressBar } from '../atoms';
import { getTimelineItemText, hasAlert } from '../helpers';
import { getProgressBarValue } from '../helpers/get-progress-bar-value';
import { getProgressText } from '../helpers/get-progress-text';
import { getTimestamps } from '../helpers/get-timestamps';
import { Header } from '../molecules';
import * as styles from './eta-header.css';
import { EtaDeliveryTimestamps } from './eta-timestamps/eta-delivery-timestamps';
import { EtaPickupPointTimestamps } from './eta-timestamps/eta-pickup-point-timestamps';
import { HeaderAlerts, getAlertMessagesByType } from './header-alerts';

type Props = {
  statuses: ProgressItem[];
  deliveryType: DeliveryType | undefined;
  locationType: PickupLocationType | undefined;
  isTrackingLink: boolean;
  carrierName?: string;
  alerts?: FrontendAlert[];
  locale: string;
  translationKey?: string;
  senderName: string;
  showAlerts?: boolean;
  showDates?: boolean;
  destination: string;
};

const hasAnyAlert = (types: FrontendAlertType[], alerts: FrontendAlert[]) =>
  types.some(type => hasAlert(type, alerts));

export const EtaHeader: FC<Props> = ({
  statuses,
  isTrackingLink,
  carrierName,
  deliveryType,
  locationType,
  alerts = [],
  locale,
  senderName,
  translationKey,
  destination,
  // when isTrackingLink is enabled, alerts are disabled, and the showAlerts value is ignored
  showAlerts = true,
  showDates = true,
}) => {
  const formatMessage = useFormatMessage();

  const currentStatus = getCurrentStatus(statuses);
  const progress = getProgressBarValue(currentStatus?.step);

  const isPickupPointDelivery = deliveryType === 'PICKUP' || deliveryType === 'INSTORE';

  const isETAOutdated = hasAnyAlert(
    ['ALERT_DELIVERY_DELAYED', 'ALERT_DELIVERY_ATTEMPT_FAILED'],
    alerts
  );

  const isETSOutdated = hasAnyAlert(
    ['ALERT_DELIVERY_DELAYED', 'ALERT_DELIVERY_ATTEMPT_FAILED', 'ALERT_SUBMISSION_DELAYED'],
    alerts
  );

  // TODO: TRACK-1322
  // We're in the testing phase of the new text headers, however ICA must keep the old one.
  // This is a temporary, low-effort, easy to rollback solution.
  // Once we're good with this, we have to find out the best approach, but some ideas are:
  // - update the progress text translation to be the same as timeline, but not for ICA
  // - add a new translation key for the new text
  // - live with that until we make the layout-based templates
  const getText = () =>
    translationKey === 'ICA'
      ? getProgressText(
          formatMessage,
          currentStatus,
          deliveryType,
          locationType,
          carrierName,
          senderName,
          translationKey
        )
      : getTimelineItemText(
          formatMessage,
          deliveryType,
          currentStatus,
          senderName,
          carrierName,
          destination,
          translationKey
        );

  const headerStatusText = isTrackingLink
    ? `${formatMessage('DELIVERY_DETAILS.DELIVERY_BY')} ${carrierName}`
    : getText();

  const headerAlerts = showAlerts ? getAlertMessagesByType(alerts, locale, translationKey) : [];

  const timestamps = getTimestamps(statuses, deliveryType, locale, formatMessage);

  const DatesComponent = isPickupPointDelivery ? EtaPickupPointTimestamps : EtaDeliveryTimestamps;

  return (
    <article data-branding="section-eta-header">
      <Header dataTest="eta-header" text={headerStatusText} />
      <ProgressBar
        progress={progress}
        currentStatus={currentStatus?.step}
        className={styles.spacing}
        alerts={showAlerts ? alerts : []}
      />
      {showDates && (
        <DatesComponent
          timestamps={timestamps}
          currentStep={currentStatus?.step}
          isETAOutdated={isETAOutdated}
          isETSOutdated={isETSOutdated}
        />
      )}
      <HeaderAlerts alerts={headerAlerts} locale={locale} />
    </article>
  );
};
