import * as maptilersdk from '@maptiler/sdk';
import { circle } from '@turf/turf';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { AnalyticsEventHandler, Coordinates } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';

import { Text } from '../../atoms/text';
import { initializeMap } from './initialize-map';
import * as styles from './map.css';

const POPUP_MARGIN = 8;

type PublicMapUIProps = {
  analyticsEventHandler: AnalyticsEventHandler;
  coordinates: Coordinates | undefined;
  hasAuthenticationEmail: boolean;
  setAuthModalVisibility: Dispatch<SetStateAction<boolean>>;
  maptilerApiKey: string;
};
export const PublicMapUI = ({
  analyticsEventHandler,
  coordinates,
  hasAuthenticationEmail,
  setAuthModalVisibility,
  maptilerApiKey,
}: PublicMapUIProps) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<maptilersdk.Map | null>(null);
  maptilersdk.config.apiKey = maptilerApiKey;
  maptilersdk.config.session = false;

  const [popupCoordinates, setPopupCoordinates] = useState<{ x: number; y: number } | null>(null);
  const formatMessage = useFormatMessage();

  const handleOnHover = (
    event: maptilersdk.MapMouseEvent & { features?: maptilersdk.MapGeoJSONFeature[] | undefined }
  ) => {
    if (!hasAuthenticationEmail) {
      return null;
    }
    const {
      point: { y, x },
    } = event;
    setPopupCoordinates({ x: x + POPUP_MARGIN, y: y + POPUP_MARGIN });
  };

  const handleOnClick = () => {
    if (!hasAuthenticationEmail) {
      return null;
    }
    analyticsEventHandler('open_verification_modal_via_map_click');
    setAuthModalVisibility(true);
  };

  useEffect(() => {
    if (map.current || !coordinates?.lat || !coordinates.lng) {
      return;
    }

    map.current = initializeMap({
      container: mapContainer.current as HTMLElement,
      center: [coordinates?.lng, coordinates?.lat],
      zoom: 11,
    });

    map.current.on('load', () => {
      map.current?.addSource('radius-circle', {
        type: 'geojson',
        data: drawCircle({ ...coordinates }),
      });

      map.current?.addLayer({
        id: 'radius-circle',
        type: 'fill',
        source: 'radius-circle',
        layout: {},
        paint: {
          'fill-color': '#767676',
          'fill-opacity': 0.5,
        },
      });

      map.current?.on('mousemove', 'radius-circle', event => {
        handleOnHover(event);
      });

      map.current?.on('mouseleave', 'radius-circle', () => {
        setPopupCoordinates(null);
      });
      map.current?.on('click', handleOnClick);
    });
  }, [coordinates]);

  return (
    <div ref={mapContainer} className={styles.mapContainer}>
      {popupCoordinates && (
        <div style={{ left: popupCoordinates.x, top: popupCoordinates.y }} className={styles.popup}>
          <Text textStyle="light1216" textColor="white">
            {formatMessage('PARCEL_DETAILS.MAP_VERIFICATION_POPUP')}
          </Text>
        </div>
      )}
    </div>
  );
};

const drawCircle = ({ lng, lat }: { lng?: number; lat?: number }) =>
  circle([lng ?? 0, lat ?? 0], 1, {
    steps: 40,
    units: 'kilometers',
  });
