import { ShippingLogo } from '@ingrid/react-shipping-logo';

import { AnalyticsEventHandler } from '@tracking/data';
import { useFormatMessage } from '@tracking/i18n';

import { ContentBlock, Stack, Text } from '../../../atoms';
import { getDataTestPrefixValue } from '../../../helpers';
import { CopyButton } from '../../../molecules';
import * as styles from '../delivery-details.css';

type DeliveryMinimalProps = {
  carrier: string | undefined;
  carrierName: string | undefined;
  trackingNumber: string | undefined;
  analyticsEventHandler: AnalyticsEventHandler;
  dataTestPrefix?: string;
};

export const DeliveryMinimal = ({
  carrier,
  carrierName,
  trackingNumber,
  analyticsEventHandler,
  dataTestPrefix,
}: DeliveryMinimalProps) => {
  const formatMessage = useFormatMessage();

  const dataTestPrefixValue = getDataTestPrefixValue(dataTestPrefix);

  return (
    <article
      data-test={`${dataTestPrefixValue}minimal-delivery-details`}
      data-branding="section-delivery-details-minimal"
    >
      {carrierName && (
        <ContentBlock
          className={styles.rootElement}
          dataTest={`${dataTestPrefixValue}minimal-delivery-by`}
        >
          <Stack>
            <Text data-branding="block-label">{formatMessage('DELIVERY_DETAILS.DELIVERY_BY')}</Text>
            <Text data-branding="block-text" textColor="darkGrey">
              {carrierName}
            </Text>
          </Stack>
          <ShippingLogo
            data-branding="shipping-logo-delivery-minimal"
            method={carrier || ''}
            variant="Round30"
            width={32}
            height={32}
            alt={formatMessage('COMMON.CARRIER_LOGO_ALT')}
            data-test={`${dataTestPrefixValue}parcel-carrier-logo-${carrierName}`}
          />
        </ContentBlock>
      )}
      {trackingNumber && (
        <ContentBlock
          data-branding="block-tracking-number"
          className={styles.rootElement}
          dataTest={`${dataTestPrefixValue}minimal-delivery-tracking-number`}
        >
          <Stack>
            <Text data-branding="block-label">
              {formatMessage('DELIVERY_DETAILS.CARRIER_TRACKING_NUMBER', {
                carrier: carrierName,
              })}
            </Text>
            <Text textColor="darkGrey" data-branding="block-text">
              {trackingNumber}
            </Text>
          </Stack>
          <CopyButton
            textToCopy={trackingNumber || ''}
            onCopy={() => analyticsEventHandler('copy_tracking_number')}
          />
        </ContentBlock>
      )}
    </article>
  );
};
