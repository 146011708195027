import { Step } from '@tracking/data';

import { TimestampsType } from '../../helpers/get-timestamps';
import { EtaLastTimestamp } from './eta-last-timestamp';
import { EtaTimestampItem } from './eta-timestamp-item';
import * as styles from './eta-timestamps.css';
import { isTimeVisible } from './is-time-visible';

type EtaDeliveryTimestampsProps = {
  timestamps: TimestampsType;
  currentStep: Step | undefined;
  isETAOutdated: boolean;
  isETSOutdated: boolean;
};

export const EtaDeliveryTimestamps = ({
  timestamps,
  currentStep,
  isETAOutdated,
  isETSOutdated,
}: EtaDeliveryTimestampsProps) => {
  const showShippedTimestamp = isTimeVisible(timestamps.shipped, isETSOutdated);

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <EtaTimestampItem
          dataTest="eta-header-timestamp-ordered"
          message={timestamps.ordered.message}
          date={timestamps.ordered.formattedTime}
        />
        {showShippedTimestamp && (
          <EtaTimestampItem
            dataTest="eta-header-timestamp-shipped"
            message={timestamps.shipped.message}
            date={timestamps.shipped.formattedTime}
          />
        )}
      </div>

      <div className={styles.column}>
        <EtaLastTimestamp
          timestamps={timestamps}
          currentStep={currentStep}
          isETAOutdated={isETAOutdated}
        />
      </div>
    </div>
  );
};
