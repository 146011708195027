import clsx from 'clsx';

import { Step } from '@tracking/data';

import { TimestampsType } from '../../helpers/get-timestamps';
import { EtaLastTimestamp } from './eta-last-timestamp';
import { EtaTimestampItem } from './eta-timestamp-item';
import * as styles from './eta-timestamps.css';
import { isTimeVisible } from './is-time-visible';

type EtaPickupPointTimestampsProps = {
  timestamps: TimestampsType;
  currentStep: Step | undefined;
  isETAOutdated: boolean;
  isETSOutdated: boolean;
};

export const EtaPickupPointTimestamps = ({
  timestamps,
  currentStep,
  isETAOutdated,
  isETSOutdated,
}: EtaPickupPointTimestampsProps) => {
  const showShippedTimestamp = isTimeVisible(timestamps.shipped, isETSOutdated);
  const showPickupDeliveryTimestamp = isTimeVisible(
    timestamps.deliveredToPickupPoint,
    isETAOutdated
  );

  const deliveredToPickupPoint = currentStep === 'STEP_DELIVERED_TO_PICKUP_POINT';

  return (
    <div
      className={clsx(styles.container, {
        [styles.deliveredToPickupPoint]: deliveredToPickupPoint,
      })}
    >
      <div className={styles.column}>
        <EtaTimestampItem
          dataTest="eta-header-timestamp-ordered"
          message={timestamps.ordered.message}
          date={timestamps.ordered.formattedTime}
        />
        {showShippedTimestamp && (
          <EtaTimestampItem
            dataTest="eta-header-timestamp-shipped"
            message={timestamps.shipped.message}
            date={timestamps.shipped.formattedTime}
          />
        )}
      </div>
      <div className={styles.column}>
        {showPickupDeliveryTimestamp && (
          <EtaTimestampItem
            dataTest="eta-header-timestamp-pickup-location"
            message={timestamps.deliveredToPickupPoint.message}
            date={timestamps.deliveredToPickupPoint.formattedTime}
          />
        )}
        <EtaLastTimestamp
          timestamps={timestamps}
          currentStep={currentStep}
          isETAOutdated={isETAOutdated}
        />
      </div>
    </div>
  );
};
