import * as maptilersdk from '@maptiler/sdk';
import { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';

import { AnalyticsEventHandler, Coordinates, DeliveryType, DirectionType } from '@tracking/data';

import { Pin } from '../../atoms';
import { getMapPadding } from '../../helpers';
import { useMapPinLogo } from './hooks';
import { initializeMap } from './initialize-map';
import { MapLocationPointerControl } from './map-location-pointer-control';
import * as styles from './map.css';
import { hasCorrectCoordinates } from './utils';

type PrivateMapProps = {
  analyticsEventHandler: AnalyticsEventHandler;
  coordinates: Coordinates | undefined;
  deliveryType?: DeliveryType;
  directionType?: DirectionType;
  maptilerApiKey: string;
  shippingMethod?: string;
};

export const PrivateMap = ({
  analyticsEventHandler,
  coordinates,
  deliveryType,
  directionType,
  maptilerApiKey,
  shippingMethod,
}: PrivateMapProps) => {
  if (!hasCorrectCoordinates(coordinates)) {
    return null;
  }

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<maptilersdk.Map | null>(null);
  maptilersdk.config.apiKey = maptilerApiKey;
  maptilersdk.config.session = false;

  const logoMapEntry = useMapPinLogo(shippingMethod, deliveryType, directionType);

  const handleOnMapCentre = () => {
    if (coordinates?.lat && coordinates.lng) {
      map?.current?.flyTo({ center: [coordinates.lng, coordinates.lat], zoom: 15 });
      analyticsEventHandler('map_centering');
    }
  };

  useEffect(() => {
    map.current = initializeMap({
      container: mapContainer.current as HTMLElement,
      center: coordinates,
      zoom: 15,
    });
    map.current?.addControl(new MapLocationPointerControl(handleOnMapCentre), 'bottom-right');
  }, [coordinates]);

  useEffect(() => {
    if (map.current && logoMapEntry) {
      const pinElement = document.createElement('div');
      createRoot(pinElement).render(
        <Pin
          offsetVertically
          src={logoMapEntry.pathPin}
          alt={logoMapEntry.name || 'Delivery location'}
          dataTest={`pin-${
            logoMapEntry.patterns.find(pattern => pattern.startsWith('carrier-')) ||
            logoMapEntry.patterns[0] ||
            'generic'
          }`}
          onLoad={event => {
            map.current?.setPadding({
              ...map.current?.getPadding(),
              top: getMapPadding(
                event.currentTarget.height,
                map.current.getContainer().offsetHeight
              ),
            });
          }}
        />
      );

      new maptilersdk.Marker({
        element: pinElement,
      })
        .setLngLat(coordinates)
        .addTo(map.current);
    }
  }, [map.current, logoMapEntry]);

  return <div ref={mapContainer} className={styles.mapContainer} />;
};
