import { AnalyticsEventHandler, DeliveryType, ProgressItem } from '@tracking/data';

import { getDataTestPrefixValue } from '../../helpers';
import { Expand, ExpandProps, ProgressText } from '../../molecules';
import * as styles from './parcel-progress-timeline.css';
import { Timeline } from './timeline';
import { TrackingProgressStack } from './tracking-progress-stack';

type ParcelProgressTimelineProps = {
  analyticsEventHandler: AnalyticsEventHandler;
  carrierName: string | undefined;
  currentStatus: ProgressItem | undefined;
  deliveryType: DeliveryType | undefined;
  destination: string;
  locale: string | undefined;
  senderName: string;
  parcelIndex: number;
  statuses: ProgressItem[];
  dataTestPrefix?: string;
  expandProps: Pick<ExpandProps, 'defaultExpanded' | 'expandToggleDisabled'>;
  translationKey: string | undefined;
};

export const ParcelProgressTimeline = ({
  analyticsEventHandler,
  carrierName,
  currentStatus,
  deliveryType,
  destination,
  locale,
  senderName,
  parcelIndex,
  statuses,
  dataTestPrefix,
  expandProps,
  translationKey,
}: ParcelProgressTimelineProps) => {
  const dataTestPrefixValue = getDataTestPrefixValue(dataTestPrefix);

  const expandedChildren = (
    <Timeline
      statuses={statuses}
      currentStatus={currentStatus}
      deliveryType={deliveryType}
      senderName={senderName}
      carrierName={carrierName}
      destination={destination}
      locale={locale}
      translationKey={translationKey}
    />
  );

  const progressStatusData = {
    currentStatus,
    deliveryType,
    carrierName,
    senderName,
    destination,
  };

  const unexpandedChildren = (
    <TrackingProgressStack
      progressTextComponent={
        <ProgressText
          progressStatusData={progressStatusData}
          data-branding="block-text"
          dataTest={`${dataTestPrefixValue}parcel-${parcelIndex}-progress`}
          translationKey={translationKey}
        />
      }
    />
  );

  return (
    <article
      className={styles.wrapper}
      data-test={`${dataTestPrefixValue}tracking-progress`}
      data-branding="section-tracking-progress"
    >
      <Expand
        {...expandProps}
        dataTest={`${dataTestPrefixValue}tracking-progress-expand`}
        expandedChildren={expandedChildren}
        unexpandedChildren={unexpandedChildren}
        onExpandPosthogCapture={() => analyticsEventHandler('expand_progress_details')}
        onHidePosthogCapture={() => analyticsEventHandler('hide_progress_details')}
      />
    </article>
  );
};
